import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { darken } from 'polished';

import * as styles from 'styles';
import Header from 'Components/Header';
import BannerLink from 'Components/BannerLink';
import LoadingSpinner from 'Components/LoadingSpinner';
import ErrorBanner from 'Components/ErrorBanner';

import { sFetch, hasValidSession } from 'session';
import { sleep } from 'utils';

const Dashboard = ({ className }) => {
  const [redirect, setRedirect] = useState(null);
  const [state, setState] = useState({
    loading: true,
    error: false,
    lists: [],
    email: null,
  });

  const location = useLocation();
  const differentEmail = location.hash === '#different-email';

  useEffect(() => {
    (async () => {
      const start = Date.now();

      const account = await hasValidSession();
      if (account) {
        setState(s => ({ ...s, email: account.email }));

        try {
          const res = await sFetch('/list');
          if (res.status === 200) {
            const data = await res.json();
            setState(s => ({ ...s, lists: data.lists }));
          }
        } catch (e) {
          setState(s => ({ ...s, error: true }));
          console.error('got an error', e);
        }
      }
      else if (account === false) {
        setRedirect('/magic-link#return=dashboard');
      }
      else if (account === null) {
        setState(s => ({ ...s, error: true }));
      }

      const elapsed = Date.now() - start;
      const wait = 250 - elapsed;
      if (wait > 0) {
        await sleep(wait);
      }

      setState(s => ({ ...s, loading: false }));
    })();
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  let override = null;
  if (state.loading) {
    override = (
      <div className="loading">
        <LoadingSpinner />
      </div>
    );
  }
  else if (state.error) {
    override = (
      <ErrorBanner refresh={true}>Failed to load</ErrorBanner>
    );
  }

  if (override) {
    return (
      <div className={className}>
        <styles.Container>
          <Header mini="true" />
          { override }
        </styles.Container>
      </div>
    );
  }

  const newList = () => {
    (async () => {
      const code = await createNewList();
      setRedirect(`/edit/${code}`);
    })();
  };

  return (
    <div className={className}>
      <styles.Container>
        <Header mini="true" />

        <div className="spaced-stack">
          <div className="account">
            Account: { state.email }
          </div>
          { differentEmail ? <ErrorBanner noMargin>Failed to create your list, use the button below.</ErrorBanner> : null }
          <BannerLink noMargin icon="fas fa-plus" onClick={newList}>Create a new list</BannerLink>
        </div>

        { state.lists.length > 0 ? <h3>Lists</h3> : null }
        { state.lists.map((list, idx) => (
          <Link to={`/edit/${list.id}`} key={idx} className="list">
            { list.name } <span><i className="fas fa-user-friends" /> { list.email_count }</span>
          </Link>
        )) }
      </styles.Container>
    </div>
  );
};

export const createNewList = async () => {
  const res = await sFetch('/list/create', {
    method: 'POST'
  });

  const json = await res.json();

  if (res.status !== 200) {
    throw json;
  }

  return json.url_code;
};

export default styled(Dashboard)`
  .loading {
    position: relative;
    min-height: 100px;
  }

  .account {
    margin-bottom: 20px;
  }

  .spaced-stack {
    & > * {
      margin: 20px 0;
    }
  }

  .list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    color: black;
    text-decoration: none;

    span {
    width: 80px;
      i {
        margin-right: 5px;
      }
    }

    &:nth-child(2n) {
      background: ${styles.$orange_back};
    }

    &:hover {
      background: black;
      color: white;
    }
  }
`;
