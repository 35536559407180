import { useRef, useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import * as styles from 'styles';
import { useParams, useLocation } from 'react-router-dom';
import LoadingSpinner from 'react-spinners/BarLoader';
import SubmitSpinner from 'react-spinners/ClipLoader';

import Header from 'Components/Header';
import BannerLink from 'Components/BannerLink';
import ErrorBanner from 'Components/ErrorBanner';
import { sleep } from 'utils';

import { sFetch, hasValidSession } from 'session';

const ListEdit = ({ className }) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    saving: false,
    downloading: false,
    authenticated: false,
    settings: {},
  });

  const [settings, setSettings] = useState({});

  const { listId } = useParams();
  const { hash } = useLocation();
  const iframe = useRef();

  useEffect(() => {
    (async () => {
      const start = Date.now();

      let res = null;
      try {
        res = await sFetch(`/list/${listId}`);
      } catch (e) {
      }

      const elapsed = Date.now() - start;
      const wait = 250 - elapsed;

      if (wait > 0) {
        await sleep(wait);
      }

      if (res) {
        if (res.status === 200) {
          const data = await res.json();
          setState(s => ({
            ...s,
            loading: false,
            authenticated: true,
            settings: data.template_data,
            stats: data.stats,
            recentEmails: data.recent_emails
          }));
        }
        else if (res.status === 401) {
          setState(s => ({ ...s, loading: false, authenticated: false }));
        }
      } else {
        setState(s => ({ ...s, error: true }));
      }
    })();
  }, []);

  if (state.error) {
    return (
      <div className={className}>
        <styles.Container>
          <Header mini="true" />
          <ErrorBanner noMargin refresh>Failed to load</ErrorBanner>
        </styles.Container>
      </div>
    );
  }

  if (state.loading) {
    return (
      <div className={className}>
        <styles.Container>
          <Header mini="true" />
          <div className="loading">
            <LoadingSpinner width="100%" height="20" />
          </div>
        </styles.Container>
      </div>
    );
  }

  const settingsName = (!settings.name && settings.name !== '') ? (state.settings.name || '') : settings.name;
  const settingsTitle = (!settings.title && settings.title !== '') ? (state.settings.title || '') : settings.title;

  const settingsHasChanges = settingsName !== (state.settings.name || '') || settingsTitle !== (state.settings.title || '');

  const cancelSettingsChange = () => {
    setSettings(state.settings);
  };

  const saveSettingsChange = () => {
    (async () => {
      const start = Date.now();
      setState(s => ({ ...s, saving: true }));

      const newSettings = {
        name: settingsName.trim(),
        title: settingsTitle.trim().length > 0 ? settingsTitle.trim() : null,
      };

      if (newSettings.name.length === 0) {
        newSettings.name = 'default';
        setSettings(s => ({ ...s, name: newSettings.name }));
      }

      const res = await sFetch(`/list/${listId}`, {
        method: 'POST',
        body: JSON.stringify({
          name: settingsName,
          template_data: newSettings,
        })
      });

      if (res.status !== 200) {
        console.error('failed to save list details');
      }

      const elapsed = Date.now() - start;
      const wait = 250 - elapsed;
      if (wait > 0) {
        await sleep(wait);
      }

      try {
        iframe.current.src = iframe.current.src;
      } catch(e) {
        debugger;
        console.error('failed to refres', e);
      }

      setState(s => ({ ...s, saving: false, settings: newSettings }));
    })();
  };

  const download = () => {
    (async () => {
      const start = Date.now();

      setState(s => ({ ...s, downloading: true }));
      const res = await sFetch(`/submissions/${listId}`);

      if (res.status !== 200) {
        console.error('failed to load submissions');
      }
      else {
        const emails = await res.text();

        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(emails));
        element.setAttribute('download', state.settings.name + '-list.txt');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

      const elapsed = Date.now() - start;
      const wait = 250 - elapsed;

      if (wait > 0) {
        await sleep(wait);
      }

      setState(s => ({ ...s, downloading: false }));
    })();
  };

  const listUrl = `https://launchlist.dev/form/${listId}`;

  return (
    <div className={className}>
      <styles.Container>
        <Header mini="true" />

        { hash === '#created' ?  (
          <BannerLink noMargin margin="10" background={styles.$orange_back} href={`${listUrl}?ref=launchlist-created`}>Your list has been created! Click here to view.</BannerLink>
        ): null }

        { hash === '#created' && !state.authenticated ? 
          <BannerLink noMargin to="/magic-link#sent">Click the <styles.MagicStyle>magic link</styles.MagicStyle> in your email to edit or see submissions.</BannerLink>
          : null
        }

        {/*<UnderConstruction />*/}
        <div className="elements">
          { state.downloading ? <div className="loading-overlay"><SubmitSpinner size="100" /></div> : null }

          <div className="settings">
            <h3>Settings</h3>
            { state.authenticated ? (
              <Fragment>
                { state.saving ? <div className="loading-overlay"><SubmitSpinner size="100" /></div> : null }
                <label>
                  product / name of list
                  <input type="text"
                    value={settingsName}
                    onChange={e => setSettings(s => ({ ...s, name: e.target.value }))}
                  />
                </label>
                <label>
                  message (optional)
                  <input type="text" placeholder={`Share your email to get updates on ${settingsName}`}
                    value={settingsTitle}
                    onChange={e => setSettings(s => ({ ...s, title: e.target.value }))}
                  />
                </label>
                  <div className="buttons">
                    { (settingsHasChanges && !state.saving) ? null : <div className="loading-overlay"></div> }
                    <button onClick={saveSettingsChange}>Save</button>
                    <button onClick={cancelSettingsChange}>Cancel</button>
                  </div>
              </Fragment>
            ) : <LockedBtn /> }
          </div>
          <div className="your-list">
            <h3>Your List</h3>
            <a href={`${listUrl}?ref=launchlist-edit`} className="preview">
              <div className="cover"></div>
              <iframe ref={iframe} src={`${listUrl}?ref=edit-preview`} title="description">
              </iframe>
            </a>
            <div className="link">
              <i className="fas fa-link" />{listUrl}
            </div>
          </div>
          <div className="analytics">
            <h3>Analytics</h3>
            { !state.authenticated ? <LockedBtn /> : (
              <Fragment>
                <div className="stats">
                  <div className="stat">
                    { state.stats.view_count_7d || '0' }
                    <span>{ state.stats.view_count_7d === 1 ? 'vistor' : 'vistors'} in last 7d</span>
                  </div>
                  <div className="stat">
                    { state.stats.email_count || '0' }
                    <span>{ state.stats.view_count_7d === 1 ? 'email' : 'emails'}</span>
                  </div>
                </div>
              </Fragment>
            ) }
          </div>
          <div className="submissions">
            <h3>
              Submissions
              <button onClick={download}><i className="fas fa-download" /> download all</button>
            </h3>
            { !state.authenticated ? <LockedBtn /> : (
              <div className="emails">
                { state.recentEmails.map(({ email }, i) => {
                  return <div key={i} className="email">{email}</div>;
                }) }

                { state.recentEmails.length === 0 ? '-- no emails --' : null }
              </div>
            ) }
          </div>
        </div>
      </styles.Container>
    </div>
  );
};

const LockedBtn = ({ className }) => {
  return (
    <BannerLink className={className} noMargin to="/magic-link" icon="fas fa-lock">
      Requires authentication. <Underline>Click here</Underline> to send a <styles.MagicStyle>magic link</styles.MagicStyle> to your email.
    </BannerLink>
  );
};

const UnderConstruction = ({ className }) => {
  return (
    <BannerLink className={className} icon="fas fa-exclamation-triangle" background={styles.$orange_back}>
      I'm working to add more analytics. Want to follow the development?
    </BannerLink>
  );
};

const Underline = styled.span`
  text-decoration: unline;
`;

export default styled(ListEdit)`
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffb5;
    z-index: 2;
  }

  .loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    & > span {
      margin-top: 10px;
      background: ${styles.$purple_back};
      & > span {
        background: ${styles.$purple_back_darker};
      }
    }
  }

  .emails {
    .email {
      &:nth-child(2n) {
        background: #E5E5E5;
      }
      &:nth-child(2n+1) {
        background: #F8F8F8;
      }

      padding: 10px 20px;
    }
  }

  .stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    background: ${styles.$green_back};

    margin: 30px 0;

    .stat {
      display: flex;
      flex-direction: column;
      padding: 10px;

      font-size: 24px;
      font-weight: bold;
      align-items: center;
      width: 150px;

      span {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  .elements {
    position: relative;

    display: grid;

    grid-gap: 10px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 500px auto auto;

    & > div > h3 {
      font-weight: normal;
      font-size: 18px;
    }

    & > div {
      margin-top: 20px;
    }

    .settings {
      display: flex;
      flex-direction: column;

      ${BannerLink} {
        flex-grow: 1;
      }

      position: relative;

      label {
        font-size: 16px;
        margin: 10px 0;
      }

      input {
        padding: 5px;
        font-size: 16px;
        clear: both;
        width: 100%;
        box-sizing: border-box;
        margin-top: 8px;
      }

      .buttons {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          flex-grow: 1;
          margin: 15px 0;

          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }

          font-size: 16px;
          padding: 5px;
          cursor: pointer;
          outline: none;
        }
      }
    }

    .your-list {
      display: flex;
      flex-direction: column;

      .preview {
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        border: solid 5px #E9E9E9;
        box-sizing: border-box;

        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 100;
        }

        &:hover {
          border-color: ${styles.$purple_back_darker};
        }

        iframe {
          border: none;
          width: 200%;
          height: 200%;
          transform: scale(0.5) translate(-50%, -50%);
        }
      }

      .link {
        padding: 20px 15px;
        background: ${styles.$green_back};
        margin-top: 10px;
        color: black;
        text-decoration: none;
        user-select: all;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        i {
        margin-right: 10px;
        }
      }
    }

    @media(max-width: 650px) {
      grid-template-rows: auto auto auto;
      .your-list {
        grid-column: 1 / -1;
      }
    }

    .analytics {
      grid-column: 1 / -1;
    }

    .submissions {
      grid-column: 1 / -1;

      h3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        button {
          border: none;
          background: none;
          cursor: pointer;
          padding: 10px;

          i {
            margin-right: 5px;
          }

          &:hover {
            background: ${styles.$purple_back};
          }
        }
      }
    }
  }
`;

