import { Fragment } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { darken } from 'polished';

import * as styles from 'styles';

const BannerLink = ({ className, to, href, onClick, children, icon }) => {
  const iconEl = icon ? <i className={icon} /> : null;

  const inner = (
    <Fragment>
      { iconEl }<div>{ children }</div>
    </Fragment>
  );

  if (to) {
    return <Link className={className} to={to}>{ inner }</Link>;
  }

  if (href) {
    return <a href={href} className={className}>{ inner }</a>;
  }

  return <button onClick={onClick} className={className}>{ inner }</button>;
};

export default styled(BannerLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    margin-right: 15px;
  }

  background: ${props => props.background || styles.$purple_back};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  ${props => (props.noMargin || props.margin) ? '' : 'margin: 40px 0;'}
  ${props => props.margin ? `margin ${props.margin}px 0;` : '' }
  cursor: pointer;
  color: black;
  text-decoration: none;
  border: none;
  outline: none;
  text-align: left;
  font-size: 18px;

  span {
    text-decoration: underline;
  }

  &:hover {
    background: ${props => darken(0.05, props.background || styles.$purple_back)};
  }
`;
