import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 860px;
  margin: 40px auto;
  box-sizing: border-box;
  padding: 0 30px;

  display: flex;
  flex-direction: column;
`;

export const MagicStyle = styled.span`
  text-decoration: unline;
  font-style: italic;
  font-weight: bold;
`;


export const $purple_back = '#F1E0FE';
export const $purple_back_darker = '#E5C6FD';
export const $input_dot_color = '#848484';
export const $green_back = '#E0FEE2';
export const $error_back = '#FEE0E0';
export const $success_back = '#E0FEE9';
export const $orange_back = '#FFEADE';

