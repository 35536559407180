import { useState, useEffect, useRef, Fragment } from 'react';
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import styled from 'styled-components';
import Spinner from 'react-spinners/BarLoader';

import * as styles from 'styles';
import Header from 'Components/Header';
import BannerLink from 'Components/BannerLink';
import ErrorBanner from 'Components/ErrorBanner';
import { sFetch, setSession, hasValidSession } from 'session';
import { sleep } from 'utils';
import { createNewList } from './Dashboard';

const MagicLink = ({ className }) => {
  const { code } = useParams();

  const [email, setEmail] = useState('');

  const [redirect, setRedirect] = useState(null);
  const [state, setState] = useState('LOADING');
  // LOADING
  // EMAIL_INPUT
  // EMAIL_INPUT_WITH_NOTE
  // EMAIL_SENDING
  // EMAIL_SENT
  // EMAIL_SENT_ALLOW_RESEND
  // VALIDATING_CODE
  // AUTHENTICATED

  const [note, setNote] = useState(null);

  const sessionCheckRef = useRef(null);
  
  const location = useLocation();
  const history = useHistory();
  const emailValid = EmailValidator.validate(email);

  let done = false;

  const getParam = (key) => {
    const v = location.hash.split(`#${key}=`)[1];
    if (v) {
      return decodeURIComponent(v.split('#')[0]);
    }
    return null;
  };

  useEffect(() => {
    setEmail(getParam('email') || '');

    (async () => {
      if (code) {
        setState('VALIDATING_CODE');

        const start = Date.now();

        const res = await sFetch('/check-auth', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${code}`
          }
        });

        const elapsed = Date.now() - start;
        const wait = 1000 - elapsed;
        if (wait > 0) {
          await sleep(wait);
        }

        if (res.status === 200) {
          setSession(code);
          setState('AUTHENTICATED');
        }
        else {
          setNote({ type: 'error', msg: 'Magic link no longer valid'});
          setState('EMAIL_INPUT_WITH_NOTE');
        }
      }
      else {
        {
          const start = Date.now();
          const currentlyValid = await hasValidSession();
          const elapsed = Date.now() - start;
          const wait = 250 - elapsed;

          if (wait > 0) {
            await sleep(wait);
          }

          if (currentlyValid) {
            return setState('AUTHENTICATED');
          }
        }

        if (location.hash === '#sent') {
          setState('EMAIL_SENT_ALLOW_RESEND');
        }
        else {
          setState('EMAIL_INPUT');
        }
      }
    })();

    return () => {
      done = true;
      if (sessionCheckRef.current) {
        clearInterval(sessionCheckRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (state === 'AUTHENTICATED') {
      const returnTo = getParam('return');

      if (returnTo === 'dashboard') {
        return setRedirect('/dashboard');
      }

      if (returnTo === 'create-list') {
        (async () => {
          const code = await createNewList();
          setRedirect(`/edit/${code}`);
        })();

        return;
      }

      if (returnTo.startsWith('edit-')) {
        const code = returnTo.substr('edit-'.length);
        setRedirect(`/edit/${code}`);
        return;
      }

      return setRedirect('/dashboard');
    }
  }, [state]);

  const sendMagicLink = (e) => {
    e && e.preventDefault();

    const emailForLink = email;
    if (!emailValid) {
      return setState('EMAIL_INPUT');
    }

    (async () => {
      // TODO: send API call
      setState('EMAIL_SENDING');

      const startTime = Date.now();
      let res = null;
      try {
        res = await sFetch('/magic-link', {
          method: 'POST',
          body: JSON.stringify({
            email: emailForLink,
          }),
        });
      } catch (e) {
      }

      const elapsed = Date.now() - startTime;
      const wait = 250 - elapsed;
      if (wait > 0) {
        await sleep(wait);
      }

      if (!res) {
        return setState('ERROR');
      }

      if (res.status !== 200) {
        const code = res.status;
        const err = await res.json();
        console.error('failed to gen magic link', code, err);

        // TODO: show that an error has occured in UI
      }

      if (sessionCheckRef.current) {
        clearInterval(sessionCheckRef.current);
      }

      let loading = false;
      sessionCheckRef.current = setInterval(() => {
        if (loading) {
          return;
        }
        loading = true;

        (async () => {
          const valid = await hasValidSession();
          loading = false;

          if (valid) {
            clearInterval(sessionCheckRef.current);
            setState('AUTHENTICATED');
          }
        })();
      }, 1000);

      setState('EMAIL_SENT');
      await sleep(10000); // 10 seconds
      setState('EMAIL_SENT_ALLOW_RESEND');
    })();
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  let content = null;
  if (state === 'EMAIL_INPUT' || state === 'EMAIL_INPUT_WITH_NOTE') {
    content = (
      <form className="auth" onSubmit={sendMagicLink}>
        { state === 'EMAIL_INPUT_WITH_NOTE' && note ? (
          <div className={`note ${note.type}`}>{note.msg}</div>
        ) : null }
        <label>
          Your email
          <input type="email" placeholder="your@email.com" value={email} onChange={e => setEmail(e.target.value)} />
          <button type="submit" className={emailValid ? '' : 'invalid'}>send <styles.MagicStyle>Magic Link</styles.MagicStyle></button>
        </label>
      </form>
    );
  }
  else if (state === 'ERROR') {
    content = (
      <ErrorBanner noMargin refresh>Failed to load</ErrorBanner>
    );
  }
  else if (state === 'AUTHENTICATED') {
    content = (
      <Fragment>
        <div className="note success">Succesfully authenticated</div>
        <BannerLink noMargin to="/dashboard" icon="fas fa-arrow-right">Go to your dashboard</BannerLink>
      </Fragment>
    );
  }
  else if (state === 'LOADING') {
    content = (
      <div className="loading">
        <div>Loading</div>
        <Spinner width="100%" height="20" />
      </div>
    );
  }
  else if (state === 'VALIDATING_CODE') {
    content = (
      <div className="loading">
        <div>Checking your Magic Link <styles.MagicStyle>magic link</styles.MagicStyle>.</div>
        <Spinner width="100%" height="20" />
      </div>
    );
  }
  else if (state === 'EMAIL_SENDING') {
    content = (
      <div className="loading">
        <div>Preparing your <styles.MagicStyle>magic link</styles.MagicStyle>.</div>
        <Spinner width="100%" height="20" />
      </div>
    );
  }
  else if (state === 'EMAIL_SENT') {
    content = (
      <div className="loading">
        <div>Waiting for your <styles.MagicStyle>magic link</styles.MagicStyle> to be clicked (check your email).</div>
        <Spinner width="100%" height="20" />
      </div>
    );
  }
  else if (state === 'EMAIL_SENT_ALLOW_RESEND') {
    content = (
      <Fragment>
        <div className="loading">
          <div>Waiting for your <styles.MagicStyle>magic link</styles.MagicStyle> to be clicked (check your email).</div>
          <Spinner width="100%" height="20" />
        </div>
        <BannerLink onClick={sendMagicLink}>Still haven't received your <styles.MagicStyle>magic link</styles.MagicStyle>? Click here to resend.</BannerLink>
      </Fragment>
    );
  }

  let banner = null;
  if (location.hash.indexOf('#return=') !== -1) {
    banner = (
      <ErrorBanner noMargin>
        Authentication is required.
      </ErrorBanner>
    );
  }

  return (
    <div className={className}>
      <styles.Container>
        <Header mini="true" />
        { banner }
        { content }
      </styles.Container>
    </div>
  );
};

export default styled(MagicLink)`
  .auth {
    margin-top: 80px;

    input, button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: solid 1px ${styles.$input_dot_color};
      margin-top: 10px;
      outline: none;
      box-sizing: border-box;
    }

    input {
      &:focus {
        border-color: black;
      }
    }

    button {
      cursor: pointer;
      background: ${styles.$purple_back};
      border-color: ${styles.$purple_back_darker};

      &:hover {
        background: ${styles.$purple_back_darker};
      }

      &.invalid, &.invalid:hover {
        background: ${styles.$purple_back};
        cursor: auto;
        opacity: 0.5;
      }
    }
  }

  .loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    & > span {
      margin-top: 10px;
      background: ${styles.$purple_back};
      & > span {
        background: ${styles.$purple_back_darker};
      }
    }
  }

  .note {
    padding: 20px;

    &.error {
      background: ${styles.$error_back};
      margin-bottom: 30px;
    }

    &.success {
      background: ${styles.$success_back};
      margin: 60px 0 10px;
    }
  }
`;
