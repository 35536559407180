import BannerLink from './BannerLink';
import * as styles from 'styles';

const Error = (props) => {
  const onClick = () => {
    if (props.refresh) {
      window.location.reload();
    }
  };

  return (
    <BannerLink { ...props }
      onClick={onClick}
      icon={ props.icon || 'fas fa-exclamation-triangle' }
      background={ styles.$error_back }
    />
  );
};

export default Error;
