import styled from 'styled-components';
import logo from 'img/logo.svg';
import { Link } from 'react-router-dom';

const Header = ({ className, mini }) => {
  return (
    <Link to={"/"} className={className}>
      <div className="screen" />
      <img src={logo} />
      <span>Easiest way to start collecting emails.</span>
    </Link>
  );
};

export default styled(Header)`
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-end;
  color: black;
  text-decoration: none;
  ${props => props.mini ? 'margin-bottom: 40px' : 'margin: 40px 0'};

  .screen {
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    user-select: none;
    width: 100%;
    max-width: ${props => props.mini ? '320px' : '660px'};
  }

  span {
    font-weight: ${props => props.mini ? 'regular' : 'bold'};
    margin-bottom: 5px;
    font-size: 18px;
  }
`;
