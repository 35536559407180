import styled from 'styled-components';
import LineSpinner from 'react-spinners/BarLoader';
import CircleSpinner from 'react-spinners/ClipLoader';

import * as styles from 'styles';

const LoadingSpinner = ({ className, style }) => {
  const elem = (!style || style === 'line')
    ? <LineSpinner width="100%" height="20" />
    : <CircleSpinner size="100" />;

  return (
    <div className={className}>
      { elem }
    </div>
  );
};

export default styled(LoadingSpinner)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;


  & > span {
    margin-top: 10px;
    background: ${styles.$purple_back};
    & > span {
      background: ${styles.$purple_back_darker};
    }
  }
`;
