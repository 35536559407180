import { API_BASE } from 'settings';

export const sFetch = (url, opts = {}) => {
  opts.headers = opts.headers || {};
  const sessionKey = window.localStorage.getItem('session');
  if (!opts.headers['Authorization']) {
    opts.headers['Authorization'] = 'Bearer ' + sessionKey;
  }
  return fetch(API_BASE + url, opts);
};

export const setSession = (code) => {
  window.localStorage.setItem('session', code);
};

export const hasValidSession = async () => {
  try {
    const session = window.localStorage.getItem('session');
    if (session) {
      const res = await sFetch('/check-auth');
      if (res.status !== 200) {
        if (session === window.localStorage.getItem('session')) {
          window.localStorage.removeItem('session');
        }
        return false;
      }
      return (await res.json());
    }
    return false;
  } catch (e) {
    return null;
  }
};
