import { useState } from 'react';

import styled from 'styled-components';
import * as EmailValidator from 'email-validator';
import { Link, Redirect } from 'react-router-dom';

import * as styles from 'styles';
import logo from 'img/logo.svg';
import num1 from 'img/num1.svg';
import num2 from 'img/num2.svg';
import num3 from 'img/num3.svg';
import generateStale from 'img/generate-stale.svg';
import generateHover from 'img/generate-hover.svg';

import Spinner from 'react-spinners/ClipLoader';
import Header from 'Components/Header';
import BannerLink from 'Components/BannerLink';
import { sleep } from 'utils';

import { sFetch } from 'session';

const Home = ({ className }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [error, setError] = useState(null);

  const validEmail = EmailValidator.validate(email);
  let emailNote = <span>(used to get email list)</span>;

  if (email.length > 0) {
    if (!validEmail) {
      emailNote = <ErrorNote>(email is not valid)</ErrorNote>;
    }
    else {
      emailNote = null;
    }
  }

  const generateLink = (e) => {
    e.preventDefault();

    if (!validEmail) {
      return false;
    }

    setLoading(true);

    (async () => {
      const listName = name.trim();

      const start = Date.now();
      let res = null;

      try {
        res = await sFetch('/list/homepage-create', {
          method: 'POST',
          body: JSON.stringify({
            list_name: listName.length > 0 ? listName : null,
            email,
          })
        });
      }
      catch (e) {
        console.error(e);
      }

      /* set min loading time to 250ms */
      const elapsed = Date.now() - start;
      const wait = 250 - elapsed;

      if (wait > 0) {
        await sleep(wait);
      }

      if (res != null) {
        const json = await res.json();
        if (res.status !== 200) {
          if (json.message === 'different email') {
            setRedirect(`/dashboard#different-email`);
          }
          else if (json.message === 'Email already has an account') {
            setRedirect(`/magic-link#return=create-list#email=${email}`);
          }
        }
        else {
          const urlCode = json.url_code;
          setRedirect(`/edit/${urlCode}#created`);
        }
      }

      setLoading(false);
    })();

    return false;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={className}>
      <styles.Container>
        <Header />
        <BannerLink to="/dashboard">already setup? <span>view dashboard</span></BannerLink>
        <Steps loading={loading} onSubmit={generateLink}>
          <div className="cover">
            <Spinner size={150} />
          </div>
          <Step icon={num1}>
            <div className="img" />
            <div className="content">
              <label>Product name <TextInput type="text" placeholder="Launch List (replace me)" value={name} onChange={e => setName(e.target.value)}/></label>
            </div>
          </Step>
          <Step icon={num2}>
            <div className="img" />
            <div className="content">
              <label>Your email <TextInput type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)}/></label> {emailNote}
            </div>
          </Step>
          <Step icon={num3} noMargin="true">
            <div className="img" />
            <div className="content">
              <GenerateBtn type="submit" className={validEmail ? 'active' : ''} />
            </div>
          </Step>
        </Steps>
      </styles.Container>
    </div>
  );
}

const Steps = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;

  .cover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ${props => props.loading ? `
  opacity: 0.5;
  .cover {
    display: flex;
  }
  ` : ''}
`;

const ErrorNote = styled.span`
  color: red;
`;

const GenerateBtn = styled.input`
  text-indent: -1000px;
  background: url('${generateStale}');
  width: 300px;
  height: 50px;
  border: none;
  margin: 10px 20px;
  cursor: pointer;

  &.active {
    background: url('${generateHover}');

    &:hover {
    }
  }
`;

const TextInput = styled.input`
  border: none;
  border-bottom: dotted 2px ${styles.$input_dot_color};
  outline: none;
  font-size: 16px;
  padding: 0;
  margin: 0 5px;

  &:focus {
    border-color: black;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 20px 0;

  .img {
    width: 42px;
    height: 56px;
    background: url('${props => props.icon}');
  }

  .content {
    ${props => props.noMargin ? '' : 'margin: 30px 20px;'}
  }
`;

export default styled(Home)`
`;
