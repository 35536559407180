import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from 'Pages/Home';
import Dashboard from 'Pages/Dashboard';
import ListEdit from 'Pages/ListEdit';
import MagicLink from 'Pages/MagicLink';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.listen((location) => {
  if (window.ga) {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
  }
});

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/magic-link/:code" component={MagicLink} />
        <Route path="/magic-link" component={MagicLink} />
        <Route path="/edit/:listId" component={ListEdit} />
      </Switch>
    </Router>
  );
}

export default App;
